import React from 'react'
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom'
import Introduction from '../views/introduction'

const Header = () => {

	return (
		<>
				<header>
					<div className="header-wrap">
						<a href="/">
							<div id="com-logo"></div>
						</a>
						<nav>
							<ul className="nav-list">
								<li><Link to="/introduction">INTRODUCTION</Link></li>
								<li><Link to="/service">SERVICE</Link></li>
								<li><Link to="/recruit">RECRUIT</Link></li>
								<li><Link to="/report">MEDIA REPORT</Link></li>
							</ul>
						</nav>
						<i className="icon_menu"></i>
					</div>
				</header>
				
		</>
	)
}

export default Header
