import React, { useState, useEffect, useRef, use } from 'react'
import { useHistory, useNavigate, useLocation } from 'react-router-dom';
import { collection, getDocs, addDoc, doc, getFirestore, getDoc } from "firebase/firestore";
import { db } from '../firebaseConfig'

const Write = () => {

    const location = useLocation();
    // console.log(location.state)

    const navigate = useNavigate();

    const [docId, setDocId] = useState(location.state);
    const [detailData, setDetailData] = useState();
    const [date, setDate] = useState();
    const [regDate, setRegDate] = useState();

    useEffect(() => {

        const newsDetailCollectionRef = doc(db, "news", String(docId.state));

        const getNews = async () => {
            const docSnap = await getDoc(newsDetailCollectionRef);
            if (docSnap.exists()) {
                // console.log("Document data:", docSnap.data());
                setDetailData(docSnap.data())
                setDate(docSnap.data().regDate.toDate())
                // console.log(docSnap.data().regDate.toDate())
            } else {
                console.log("No such document!");
            }
        }
        getNews();

    }, [docId])

    useEffect(() => {
        if (date) {
            const date1 = date.getFullYear()
            const date2 = date.getMonth() + 1
            const date3 = date.getDate()
            setRegDate(date1 + "." + date2 + "." + date3)
        }
    }, [date])

    const backPage = () => {
        navigate(-1)
    }

    return (
        <>
            {detailData &&
                <div className="service">
                    <main>
                        <section className="tpi bg_04">
                            <div className="wrp flx-center">
                                <div className="msg">
                                    <div className="tit">언론보도</div>
                                    <div className="mit">새로운 소식과 정확한 정보로 앞서 나가겠습니다.</div>
                                </div>
                            </div>
                        </section>

                        <section className="mtpt srws" id="news">
                            <div className="wrp w-detail">
                                <button type="button" className="gry-btn arw back" onClick={backPage}>리스트로 돌아가기</button>
                                <h2>{detailData.title}</h2>
                                <div className="dcr">{regDate}</div>
                                <article dangerouslySetInnerHTML={{ __html: detailData.content }}>
                                    { }
                                </article>
                                <div className="txt-right">
                                    <button type="button" className="gry-btn arw detail" onClick={backPage}>리스트 보기</button>
                                </div>
                            </div>
                        </section>
                    </main>
                </div>
            }

        </>
    );

};

export default Write;