import React from 'react'

const Footer = () => {

	return (
		<>
			<footer>
				<div className="wrp">
					<div>
						<div className="logo" data-href="/"></div>
						<ul className="acp">
							<li>(주)핀테크</li>
							<li>대표자 김갑영 ∣ 사업자등록번호 113-86-53976</li>
							<li>07320 서울특별시 영등포구 여의대로 24, 전경련회관 43층 (여의도동, FKI 타워)</li>
							<li>전화 02-6956-4148 (09:00 ~ 18:00) ∣ 이메일 <a
								href="mailto:contact@fintech1.co.kr">contact@fintech1.co.kr</a></li>
						</ul>
					</div>
					<div>
						<ul className="links">
							<a href="https://apps.apple.com/kr/app/apple-store/id1447928072" target="_blank"><li className="ics api"></li></a>
							<a href="https://play.google.com/store/apps/details?id=com.fintech.smart.lenket" target="_blank"><li className="ics ggi"></li></a>
							{/* <a href="https://www.youtube.com/channel/UC31e5ebNdoNga6qXH8CRdjw" target="_blank"><li className="ics yti"></li></a> */}
						</ul>
						<p className="cpr">Copyright &copy; FINTECH INC. All rights reserved.</p>
					</div>
				</div>
			</footer>
		</>
	)
}

export default Footer
