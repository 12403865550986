import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";



const firebaseConfig = {
  apiKey: "AIzaSyBz7-rji-0O5keuG42erQzCzkCErx4tAEA",
  authDomain: "fintechnew-4a4d8.firebaseapp.com",
  projectId: "fintechnew-4a4d8",
  storageBucket: "fintechnew-4a4d8.appspot.com",
  messagingSenderId: "285881854277",
  appId: "1:285881854277:web:42f15a2a83e4645109f62c",
  measurementId: "G-CLMKS4EB8Z"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);