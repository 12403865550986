import React, { useState, useEffect, useRef, use } from 'react'
import { useHistory, useNavigate, useLocation, Link } from 'react-router-dom';
import { collection, getDocs, addDoc, doc, deleteDoc, orderBy, query } from "firebase/firestore";
import { db } from '../firebaseConfig'

const Setting = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const newsCollectionRef = collection(db, "news");
    const mediaCollectionRef = collection(db, "media");
    const historyCollectionRef = collection(db, "history");

    const [historyData, setHistoryData] = useState();
    const [newsData, setNewsData] = useState();
    const [mediaData, setMediaData] = useState();
    const [type, setType] = useState("1");
    const [pwCheck, setPwCheck] = useState(location.state ? location.state : false);
    const [pw, setPw] = useState("");

    useEffect(() => {
        getDataNews()
        getDataMedia()
        getDataHistory()
    }, [])


    const getDataNews = () => {
        const getNews = async () => {
            const q = query(newsCollectionRef, orderBy("regDate", "desc"))
            const data = await getDocs(q);
            setNewsData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }
        getNews();
    }


    //언론홍보
    const getDataMedia = () => {
        const getMedia = async () => {
            const q = query(mediaCollectionRef, orderBy("regDate", "desc"))
            const data = await getDocs(q);
            setMediaData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
            // console.log(newsData);
        }
        getMedia();
    }

    //연혁
    const getDataHistory = () => {
        const getHistory = async () => {
            const q = query(historyCollectionRef, orderBy("title", "desc"))
            const data = await getDocs(q);
            setHistoryData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }
        getHistory();
    }

    const del = async (id) => {

        if (type === "1") {
            const delDoc = doc(db, "news", id);
            await deleteDoc(delDoc);
            getDataNews()
        } else if (type === "2") {
            const delDoc = doc(db, "history", id);
            await deleteDoc(delDoc);
            getDataHistory()
        } else {
            const delDoc = doc(db, "media", id);
            await deleteDoc(delDoc);
            getDataMedia()
        }

        alert("삭제완료되었습니다.")
    }

    const pwChk = () => {
        if(pw === "fintech21!@"){
            setPwCheck(true)
        } else {
            alert("비밀번호가 일치하지 않습니다.")
            setPwCheck(false)
        }
    }

    return (
        <>
            <div className="report write">
                <main>
                    <section className="tpi bg_04">
                        <div className="wrp flx-center">
                            <div className="msg">
                                <div className="tit">관리 페이지</div>
                            </div>
                        </div>
                    </section>
                    {
                        pwCheck === false ?
                            <>
                                <div className="" style={{ height: "200px", backgroundColor: "white", color: "black", textAlign: "center", paddingTop: "40px" }}>
                                    <div className="" >
                                        <p style={{fontSize:"20px", marginBottom:"20px"}}>관리페이지 비밀번호</p>
                                        {/* <p style={{fontSize:"15px", marginBottom:"10px"}}>fintech21!@</p> */}
                                        <input type="password" onChange={(event) => { setPw(event.target.value) }} style={{ paddingLeft: "5px", fontSize: "15px", color: "black", backgroundColor: "white", height: "25px", width: "10%", border: "1px solid gray" }}></input>
                                        <button onClick={pwChk} style={{ width: "35px", height: "25px", marginLeft:"5px" }}>확인</button>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="" style={{ height: "100px", backgroundColor: "white", color: "black", textAlign: "center", paddingTop: "40px" }}>
                                    <div className="" >
                                        <button onClick={() => setType('1')} autoFocus className="btn-set" style={{ marginRight: "25px" }}>새로운 소식</button>
                                        <button onClick={() => setType('2')} className="btn-set" style={{ marginRight: "25px" }}>연혁</button>
                                        <button onClick={() => setType('3')} className="btn-set" >언론홍보</button>
                                    </div>
                                </div>

                                <section className="mtpt srws" id="news">
                                    <div className="wrp" style={{ paddingBottom: "1rem" }}>
                                        <div style={{ marginTop: "20px" }}>
                                            <button style={{ padding: "5px", marginBottom: "30px" }}><Link to="/write">글 등록하기</Link></button>
                                        </div>
                                        {type === "3" && <span style={{ color: "black", fontSize: "15px" }}>* 최근 게시글 2개가 노출됩니다.</span>}
                                    </div>


                                    <div className="wrp">
                                        <table className="table" style={{ color: "black", borderCollapse: "collapse", width: "100%", textAlign: "center", fontSize: "17px" }}>
                                            <thead style={{ height: "60px" }}>
                                                <tr>
                                                    <th>NO</th>
                                                    <th>제목</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    newsData && type === "1" &&
                                                    newsData.map((item, index) => {
                                                        return (
                                                            <tr key={index} style={{ height: "40px", verticalAlign: "middle" }}>
                                                                <td style={{ width: "5%" }}>{index + 1}</td>
                                                                <td style={{ width: "30%" }}>{item.title}</td>
                                                                <td style={{ width: "30%" }}>
                                                                    <button className="sjb btn-mod" onClick={() => navigate("/write", { state: item })}>수정</button>
                                                                    <button className="sjb btn-del" onClick={() => del(item.id)}>삭제</button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                {
                                                    historyData && type === "2" &&
                                                    historyData.map((item, index) => {
                                                        return (
                                                            <tr key={index} style={{ height: "40px", verticalAlign: "middle" }}>
                                                                <td style={{ width: "5%" }}>{index + 1}</td>
                                                                <td style={{ width: "30%" }}>{item.title}</td>
                                                                <td style={{ width: "30%" }}>
                                                                    <button className="sjb btn-mod" onClick={() => navigate("/write", { state: item })}>수정</button>
                                                                    <button className="sjb btn-del" onClick={() => del(item.id)}>삭제</button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                {
                                                    mediaData && type === "3" &&
                                                    mediaData.map((item, index) => {
                                                        return (
                                                            <tr key={index} style={{ height: "40px", verticalAlign: "middle" }}>
                                                                <td style={{ width: "5%" }}>{index + 1}</td>
                                                                <td style={{ width: "30%" }}>{item.title}</td>
                                                                <td style={{ width: "30%" }}>
                                                                    <button className="sjb btn-mod" onClick={() => navigate("/write", { state: item })}>수정</button>
                                                                    <button className="sjb btn-del" onClick={() => del(item.id)}>삭제</button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </section>
                            </>
                    }

                </main>
            </div>

        </>
    );

};

export default Setting;