import React, { useState, useEffect, useRef } from 'react'
import { collection, getDocs } from "firebase/firestore";
import $ from 'jquery';


const Index = () => {

  useEffect(() => {

    class StickyText {

      constructor(selector) {
        var $ = window.$;
        this.selector = selector
        // this.element = $(this.selector)
        if (this.selector.length) {
          this.set = this.set.bind(this)
          this.set()
          this.onScroll()
          this.onResize()
        }
        this.left = 0;
        this.top = undefined;
        this.width = undefined;
      }

      onScroll() {
        if (window.innerWidth < - 1024) return
        const $wtf = $(this.selector)
        $(window).on("scroll", () => {
          const y = window.scrollY;
          this.ary.some((it, idx) => {
            if (y <= it) {
              $(".wtf").removeClass("show");
              $(".wtf").eq(idx).addClass("show");
              return true
            }
          })
          this.setWtf()
        })
      }

      onResize() {
        if (window.innerWidth < - 1024) return
        $(window).on("resize", () => {
          this.set();
        })
      }

      set() {
        const ary = this.ary = [];
        $(".img-hld").each(function () {
          ary.push($(this).offset().top + ($(this).height() * 3 / 4))
        })
        // console.log(ary)
        this.setWtf()
      }

      setWtf() {
        if (typeof $(".img-hld").offset() !== 'undefined') {
          const le = $(".img-hld").offset().left + $(".img-hld").width() - window.scrollX
          $(".wtf").css("left", le)
        } else {
          return
        }
      }
    }

    const sticky = new StickyText(".wtf")
  }, [])


  return (
    <>
      <div className="body home">
        <main>

          <section className="main-video-section">
            <div className="wrp">
              <video className="main-video" poster="/media/bg_post_frame.jpg" autoPlay muted playsInline loop>
                <source src="/media/bg.mp4" type="video/mp4" />
              </video>

              <div className="e-slg">GROW TOGETHER</div>
              <div className="pt-br"></div>
              <div className="k-slg">새로운 <strong>핀테크</strong> 문화를 창조합니다.</div>
            </div>
          </section>

          <section className="wss">
            <div className="slg">
              <span>What We Do</span>
            </div>
          </section>

          <section className="idl">
            <div className="wrp w192">
              <div className="wtf show">
                <h3>일상 속의 <strong>핀테크</strong></h3>
                <p>우리의 데이터 정보들이 “모니”에 담겨 있습니다.<br />
                  (주)핀테크는 다양한 사람들의 삶의 기록인 데이터를 손쉽게 사용할 수 있도록 기술 플랫폼으로의 변화를 추구하며, 현재를 넘어 다음 세대를 위한 지식의 토대를 제공하겠습니다.
                </p>
              </div>
              <div className="cvu"></div>
              <div className="img-hld">
                <div className="img"></div>
                <p className="cap">Fintech<br /><strong>In Daily Life</strong></p>
              </div>
            </div>
          </section>

          <section className="alc">
            <div className="wrp w192">
              <div className="img-hld">
                <div className="img"></div>
                <p className="cap">Always<br /><strong>Considerate</strong></p>
              </div>
              <div className="wtf">
                <h3>고객 입장에서 <strong>생각하는 서비스</strong></h3>
                <p>(주)핀테크에서 진행하는 모든 프로젝트와 운영은 나의 비즈니스라고 생각하고 임합니다.
                  프로젝트와 운영을 하다보면 사업 분야와 목적이 다르기 때문에 고객사마다 다양한 니즈가 있습니다.
                  한 번 맺은 인연을 중요하게 여기는 작은 운영 하나라도 겸손하게 고객 입장에서 생각하는 서비스를 추구합니다.</p>
              </div>
            </div>
          </section>

          <section className="sin">
            <div className="wrp w192">
              <div className="wtf">
                <h3><strong>디테일한 분석</strong>에서 도출</h3>
                <p>기초와 기본이 없는 스마트는 있을 수 없습니다.
                  수많은 프로젝트의 경험을 바탕으로 목적에 맞는 디테일한 분석과 케이스랩만의 방법론을 통해 누군가가 모방하고 싶어하는 최상의 스마트한 프로젝트 결과물을 만들어 냅니다.</p>
              </div>
              <div className="cvu reverse"></div>
              <div className="img-hld">
                <div className="img"></div>
                <p className="cap">Smart<br /><strong>Innovation</strong></p>
              </div>
            </div>
          </section>
        </main>

      </div>
    </>
  );
}

export default Index;
