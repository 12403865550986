import React, { useState, useEffect, useRef, use } from 'react'
import { useHistory, useNavigate, useLocation } from 'react-router-dom';
import { Editor } from '@toast-ui/react-editor'
import '@toast-ui/editor/dist/toastui-editor.css'
import '@toast-ui/editor/dist/i18n/ko-kr';
import { collection, getDocs, addDoc, doc, updateDoc } from "firebase/firestore";
import { db } from '../firebaseConfig'

const Write = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const editorRef = useRef();

    const [data, setData] = useState(location.state);   //수정화면 데이터
    const [id, setId] = useState(data ? data.id : "");
    const [title, setTitle] = useState(data ? data.title : "");
    const [content, setContent] = useState(data ? data.content : "");
    const [type, setType] = useState(data ? data.type : "news");
    const [regDate, setRegDate] = useState("");
    const [updDate, setUpdDate] = useState("");
    const [editorPlaceholder, setEditorPlaceholder] = useState("");
    const [imgName, setImgName] = useState(data ? data.imgName : "");
    const [imgUrl, setImgUrl] = useState(data ? data.mainImg : "");
    const [selected, setSelected] = useState(data ? data.type : "");

    //에디터 내용 content에 담기
    const onChange = () => {
        const content = editorRef.current.getInstance().getHTML();
        const data2 = editorRef.current.getInstance().getMarkdown();

        setContent(content)
    };

    useEffect(() => {
        if (type === "history") {
            setEditorPlaceholder('년도를 입력해주세요.')
        } else {
            setEditorPlaceholder('제목을 입력해주세요.')
        }
    }, [type])

    //글 등록하기
    const onClick = async () => {
        try {
            const res = await addDoc(collection(db, type), {
                title: title,
                content: content,
                type: type,
                regDate: new Date(),
                updDate: updDate,
                mainImg: imgUrl,
                imgName: imgName
            })
            alert("등록이 완료되었습니다.")
            navigate("/setting", { state: true })
        } catch (e) {
            alert("등록을 실패하였습니다.")
            console.log(e)
        }
    }

    //수정하기
    const updateUser = async () => {
        const updDoc = doc(db, data.type, data.id)
        const newField = {
            title: title,
            content: content,
            updDate: new Date(),
            mainImg: imgUrl,
            imgName: imgName
        };
        await updateDoc(updDoc, newField);
        alert("수정이 완료되었습니다.")
        navigate("/setting", { state: true })
    }

    // 게시판 유형 selectbox
    const handleSelect = (e) => {
        setType(e.target.value);
    };

    //대표이미지 업로드
    const onUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (e) {
            const rawLog = reader.result;
            setImgUrl(rawLog)
        };
        setImgName(file.name)

    }

    return (
        <>
            <div className="report write">
                <main>
                    <section className="tpi bg_04">
                        <div className="wrp flx-center">
                            <div className="msg">
                                <div className="tit">게시글 등록하기</div>
                            </div>
                        </div>
                    </section>

                    <section className="mtpt srws" id="news">
                        <div className="wrp">
                            <div className="wtd">
                                <span className="wts">유형</span>
                                {
                                    data === null ?
                                        <select onChange={handleSelect} value={type} style={{ height: "40px", width: "120px", color: "black", backgroundColor: "white", fontSize: "15px" }}>
                                            <option value="news">새로운 소식</option>
                                            <option value="history">연혁</option>
                                            <option value="media">언론홍보</option>
                                        </select>
                                        :
                                        <span className="wtss">{data.type === "news" ? "새로운 소식" : data.type === "history" ? "연혁" : data.type === "media" ? "언론홍보" : ""}</span>

                                }
                            </div>
                            <div className="wtd">
                                <span className="wtst">제목</span>
                                <input type="text" placeholder={editorPlaceholder} defaultValue={title} onChange={(event) => { setTitle(event.target.value) }} style={{ paddingLeft: "5px", fontSize: "15px", color: "black", backgroundColor: "white", height: "40px", width: "90%", border: "1px solid gray" }}></input>
                            </div>
                            {
                                type === "news" &&

                                <div className="wtd">
                                    <span className="wtsr">대표 이미지</span>
                                    <input type="file" accept="image/*" onChange={e => onUpload(e)} style={{ width: "85px" }} /><span style={{ color: "black" }}>{imgName && imgName}</span>
                                </div>
                            }
                            {
                                type === "history" &&

                                <div className="wtv">
                                    <span>* 글머리 기호를 사용하여 입력해주세요.</span>
                                </div>
                            }
                            {
                                type === "media" &&

                                <div className="wtd">
                                    <span className="wtsr">유튜브URL</span>
                                    <input type="text" placeholder="유튜브 URL을 입력해주세요." defaultValue={content} onChange={(event) => { setContent(event.target.value) }} style={{ paddingLeft: "5px", fontSize: "15px", color: "black", backgroundColor: "white", height: "40px", width: "90%", border: "1px solid gray" }}></input>
                                </div>
                            }
                            {
                                type !== "media" &&

                                <Editor
                                    placeholder="내용을 입력해주세요."
                                    previewStyle="vertical" // 미리보기 스타일 지정
                                    height="600px" // 에디터 창 높이
                                    initialEditType="wysiwyg" // 초기 입력모드 설정(디폴트 markdown)
                                    language="ko-KR" //기본언어 한국어로
                                    toolbarItems={[
                                        // 툴바 옵션 설정
                                        ['heading', 'bold', 'italic', 'strike'],
                                        ['hr', 'quote'],
                                        ['ul', 'ol', 'task', 'indent', 'outdent'],
                                        ['table', 'image', 'link'],
                                        ['code', 'codeblock']
                                    ]}
                                    ref={editorRef}
                                    onChange={onChange}
                                    initialValue={content}
                                ></Editor>
                            }
                            <div className="wrt-btn">
                                {location.state ?
                                    <button onClick={updateUser} style={{ marginRight: "10px" }}>수정하기</button>
                                    :
                                    <button onClick={onClick} style={{ marginRight: "10px" }}>등록하기</button>
                                }
                                <button onClick={() => navigate("/setting", { state: true })}>취소</button>
                            </div>
                        </div>
                    </section>

                </main>
            </div>

        </>
    );

};

export default Write;