import React, { useState, useEffect, useRef, use } from 'react'
import { Link } from 'react-router-dom'
import { collection, getDocs, addDoc, orderBy, query } from "firebase/firestore";
import { db } from '../firebaseConfig'

const Report = () => {

    const newsCollectionRef = collection(db, "news");
    const mediaCollectionRef = collection(db, "media");
    const [newsData, setNewsData] = useState();
    const [mediaData, setMediaData] = useState();


    useEffect(() => {
        const getNews = async () => {
            const q = await query(newsCollectionRef, orderBy("regDate", "desc"))
            const data = await getDocs(q);
            setNewsData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
            // console.log(newsData);
        }
        getNews();

    }, [])

    //언론홍보
    useEffect(() => {
        const getNews = async () => {
            const q = await query(mediaCollectionRef, orderBy("regDate", "desc"))
            const data = await getDocs(q);
            setMediaData((data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))).slice(0, 2))
            // console.log(newsData);
        }
        getNews();

    }, [])

    const setIFrames = () => {
        document.querySelectorAll(".scr iframe").forEach((it) => {
            const w = it.clientWidth
            const h = w * 0.5625
            it.style.height = h + "px"
        })
    }
    window.addEventListener("resize", setIFrames)
    setIFrames()

    return (
        <>
            <div className="report">
                <main>
                    <section className="tpi bg_04">
                        <div className="wrp flx-center">
                            <div className="msg">
                                <div className="tit">언론보도</div>
                                <div className="mit">핀테크의 새로운 소식과 홍보 영상을 확인하실 수 있습니다.</div>
                            </div>
                        </div>
                    </section>

                    <section className="mtpt srws" id="news">
                        <div className="wrp">
                            <div className="s_tit">
                                <span>새로운 소식</span>
                            </div>
                            {/* <button><Link to="/write">글 등록하기</Link></button> */}
                            {/* <button><Link to="/setting">관리페이지</Link></button> */}

                            <ul className="vdl mt70">
                                {
                                    newsData &&
                                    newsData.map((item, index) => {
                                        const content = item.content.replace(/<[^>]*>?/g, '');
                                        return (
                                            <li key={index}>
                                                <figure data-href="">
                                                <Link to="/newsDetail" state={{ state: item.id }}>
                                                    <img src={item.mainImg} />
                                                </Link>
                                                </figure>
                                                <div className="tit">{item.title}</div>
                                                <p style={{ height: "60px" }}>{content}</p>
                                                <div className="txt-right mt25"><button type="button" className="gry-btn arw"><Link to="/newsDetail" state={{ state: item.id }}>자세히 보기</Link></button></div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </section>

                    <section className="bbg mtpt mvs" id="report">
                        <div className="wrp">
                            <div className="s_tit">
                                <span>언론홍보</span>
                            </div>

                            <div className="scr mt60 wrp">
                                {/* <div>
                                    <iframe src="https://www.youtube.com/embed/0fSEP-Y4JcY" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    <p>제대로 바뀐 모니! 모니에서 확인할 수 있는 나의 마이데이터는 뭘까?</p>
                                </div>
                                <div>
                                    <iframe src="https://www.youtube.com/embed/wxiCXxgBvJw" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    <p>올뉴렉스턴 더블랙을 실제로 타보면? 여자들에게도 참 좋다! (2탄 / 시승 편)</p>
                                </div> */}

                                {
                                    mediaData &&
                                    mediaData.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                <iframe src={item.content} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                <p>{item.title}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            <div className="ytb">
                                <a href="https://www.youtube.com/channel/UC31e5ebNdoNga6qXH8CRdjw" target="_blank"><img className="y-badge" /></a>
                            </div>
                        </div>
                    </section>

                </main>
            </div>

        </>
    );

};

export default Report;