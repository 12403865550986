import React, { useState, useEffect, useRef } from 'react'
import $ from 'jquery';

const Recruit = () => {

    // const [mounted, setMounted] = useState(false);
    const mounted = useRef()

    useEffect(() => {
        // setMounted(true);
        mounted.current = true

        return () => mounted.current = false
    }, []);

    return (
        <>
            <div className="body service">
                <main>

                    <section className="tpi bg_03">
                        <div className="wrp flx-center">
                            <div className="msg">
                                <div className="tit">채용정보</div>
                                <div className="mit">논리적인 사고력과 창의적인 생각, 뜨거운 심장을 가지신 분을 모집합니다.</div>
                            </div>
                        </div>
                    </section>

                    <section className="rcf">
                        <div className="wrp">
                            <p>핀테크는 <em>지속 가능한 교류</em>와 <em>커뮤니케이션</em>을 통해<br />일하고 싶은 사람이 일할 수 있는 구조를 만드는 <em>선순환 구조</em>를 채택하고 지향합니다.</p>
                            <p className="sub_con">핀테크의 탄생은 실력있는 IT경력자가 오랫동안 일 할 수 있는 환경을 제공하자는 고민에서 출발했습니다.<br />핀테크의 분야별 경력자 그룹은 차별없이 동등한 회사의 혜택과 복지를 제공합니다.</p>
                            <div className="rcf_desc">
                                <div>
                                    <div className="icon_rcf_01"></div>
                                    <div className="tit">근무조건</div>
                                    <ul className="ul">
                                        <li>주 5일 근무, 정규직, 연봉제</li>
                                        <li>AM 9시 ~ PM 6시</li>
                                    </ul>
                                </div>
                                <div>
                                    <div className="icon_rcf_02"></div>
                                    <div className="tit">복리후생</div>
                                    <ul className="ul">
                                        <li>연차휴가 / 4대보험 /복지카드 지원</li>
                                        <li>자기계발비 지원(도서비, 컨퍼런스 등)</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>


                    <section className="cya mtpt">
                        <div className="wrp">
                            <div className="s_tit">
                                <span>채용안내</span>
                            </div>
                        </div>
                        <div className="cyi wrp">
                            <li className="note">
                                <div className="tit">Product Manager</div>
                                <p>폭넓은 지식 및 경험과 다양한 의견을 수용 할 줄아는 기획자 채용</p>
                                <ul>
                                    <li>Product Owner</li>
                                    <li>Data Analyst</li>
                                </ul>
                            </li>
                            <li className="pen">
                                <div className="tit">Designer</div>
                                <p>Creative한 감각을 가진 신입/경력 디자이너 채용</p>
                                <ul>
                                    <li>Design Directer &amp; Manager</li>
                                    <li>Web / Mobile UI Designer</li>
                                </ul>
                            </li>
                            <li className="console">
                                <div className="tit">Publisher</div>
                                <p>서비스와 사용자에 대한 이해가 높은 신입 / 경력 퍼블리셔 채용</p>
                                <ul>
                                    <li>Html / CSS</li>
                                    <li>Jquery / Javascript</li>
                                </ul>
                            </li>
                            <li className="gear">
                                <div className="tit">Developer</div>
                                <p>직관력 있는 웹 및 모바일 개발환경에 익숙한 전문가 채용</p>
                                <ul>
                                    <li>Javascript</li>
                                    <li>JSP / PHP / ASP / DATABASE</li>
                                </ul>
                            </li>
                        </div>
                    </section>

                </main>
            </div>

        </>
    );

};

export default Recruit;