import React, { useState, useEffect, useRef } from 'react'
import $ from 'jquery';

const Service = () => {
    const mounted = useRef()
    useEffect(() => {
        mounted.current = true

        
        $.fn.scrollEnd = function (callback, timeout) {
            timeout = timeout || 200;
            $(this).scroll(function () {
                var $this = $(this);
                if ($this.data('scrollTimeout')) {
                    clearTimeout($this.data('scrollTimeout'));
                }
                $this.data('scrollTimeout', setTimeout(function () {
                    callback($this);
                }, timeout));
            });
        };
        
        document.querySelectorAll(".cat").forEach(function (elem, idx) {
            elem.addEventListener("click", function (e) {
                const target = e.currentTarget || e.target;
                target.classList.toggle("on");
                $(target).siblings().removeClass("on");
            });
        });

        document.querySelectorAll(".ngns .arrow").forEach(function (element) {
            element.addEventListener("click", function (e) {
                const target = e.target;
                let dir = "next";
                if (target.classList.contains("prev")) dir = "prev";
                const w = $(".ngns .f_slider li").width();
                const slider = document.querySelector(".ngns .f_slider");
                let scrollLeft = slider.scrollLeft;
                if (dir == "next") {
                    scrollLeft += w;
                } else {
                    scrollLeft -= w;
                }

                getXMandatory($(slider));
                $(slider).animate({
                    scrollLeft: scrollLeft
                }, setXMandatory);
            });
        });

        document.querySelectorAll(".slider_dots li").forEach(function (element) {
            element.addEventListener("click", function (e) {
                const target = e.target;
                $(target).addClass("on").siblings().removeClass("on");
                const idx = $(target).index();
                const w = $(".f_slider li").width();

                getXMandatory($(".f_slider"));
                $(".f_slider").animate({
                    scrollLeft: w * idx
                }, setXMandatory);
            });
        });


        $(".f_slider").scrollEnd(function (element) {
            const scrollLeft = $(element).scrollLeft();
            const w = $(element).children("li").width();
            const idx = parseInt(scrollLeft / w);
            setDots(idx);
        });

        function setDots(idx) {
            $(".slider_dots li").eq(idx).addClass("on").siblings().removeClass("on");
            if (idx === 0) {
                $(".ngns .prev").addClass("hide");
            } else {
                $(".ngns .prev").removeClass("hide");
            }

            if (idx === $(".f_slider li").length - 1) {
                $(".ngns .next").addClass("hide");
            } else {
                $(".ngns .next").removeClass("hide");
            }
        }

        function getXMandatory($element) {
            // if (!CLIENT.browser.safari) $element.removeClass("xMandatory");
        }

        function setXMandatory() {
            // if (!CLIENT.browser.safari) $(this).addClass("xMandatory");
        }

        function getBgPosition() {
            // console.log("hi")
            if(typeof $(".rdp").offset() !== 'undefined'){
                const x = $(".rdp").offset().left + ($(".rdp").width() / 2) - (parseFloat($(".rdp").css("background-size").split(" ")[0]) / 2) - window.scrollX
                $(".rdp").css("background-attachment", "fixed")
                $(".rdp").css("background-position", `${x}px 50%`)
            } else {
                return
            }
        }

        $(window).on("resize scroll", () => {
            getBgPosition()
        })
        getBgPosition()
        return () => mounted.current = false

    }, [])


    return (
        <>
            <div className="service">
                <main>

                    <section className="tpi bg_02">
                        <div className="wrp flx-center">
                            <div className="msg">
                                <div className="tit">모니 서비스 소개</div>
                                <div className="mit">금융을 기반으로 일상 전반의 정보를 추천해주는, 모니 어플리케이션</div>
                            </div>
                        </div>
                    </section>

                    <section className="moni-dw">
                        <div className="wrp">
                            <div className="mvt">
                                <ul className="njb">
                                    <li>내정보가,</li>
                                    <li className="emp">모니로 새로워지다!</li>
                                    <li className="desc mt20">내 정보에 빅데이터를 더해<br />
                                        <em>조회하고 쉽게 관리하는 차별화된 나만의 리포트</em></li>
                                </ul>
                                <ul className="stores mt55">
                                    <li className="app_store" data-href="https://apps.apple.com/kr/app/apple-store/id1447928072, _blank"></li>
                                    <li className="google_play" data-href="https://play.google.com/store/apps/details?id=com.fintech.smart.lenket, _blank"></li>
                                    <li className="moni_web" data-href="http://moneey.co.kr, _blank"></li>
                                </ul>
                            </div>
                            <div className="phn"></div>
                        </div>
                    </section>

                    <section className="wbg mtpt">
                        <div className="wrp mnw pb150">
                            <div className="s_tit">
                                <span>모니 일상</span>
                            </div>

                            <ul className="mnys mt100">
                                <li className="credit">
                                    <div>신용</div>
                                    <div>내 신용점수와 상세내역을 확인하고, 나와 비슷한 사람들과의 비교도 할 수 있어요.</div>
                                </li>
                                <li className="car">
                                    <div>자동차</div>
                                    <div>차량 조회에서 운전 관리까지 자동차(운전)에 관련된 다양한 정보를 확인할 수 있어요.</div>
                                </li>
                                <li className="carrier">
                                    <div>커리어</div>
                                    <div>내 직장 정보 및 최근 3년 소득금액을 확인할 수 있어요.</div>
                                </li>
                                <li className="family">
                                    <div>패밀리</div>
                                    <div>우리 가족 기본 정보와 함께 생일 알림도 확인하고, 주변 교육시설까지 조회할 수 있어요.</div>
                                </li>
                                <li className="health">
                                    <div>헬스</div>
                                    <div>건강검진, 내 진료내역 등 모니의 건강신호로 쉽게 알수있어요.</div>
                                </li>
                                <li className="travel">
                                    <div>여행</div>
                                    <div>야영(관광)장 및 관광지 정보를 지역별로 선택하여 조회할 수 있어요.</div>
                                </li>
                                <li className="town">
                                    <div>타운</div>
                                    <div>집 근처 아파트의 시세와 사고다발지역 정보를 한번에 확인할 수 있어요.</div>
                                </li>
                                <li className="choice">
                                    <div>초이스</div>
                                    <div>내 지역 국회의원의 기본 정보부터 당선공약 및 발의 법안까지 확인할 수 있어요.</div>
                                </li>

                            </ul>
                        </div>
                    </section>

                    <section className="lbg mtpt">
                        <div className="wrp rwp">
                            <div className="rdp"></div>
                            <div className="rpi">
                                <div className="tit">나의 데이터 공유하기</div>
                                <div className="sub_tit mt10">내 정보를 안전하고 간편하게!</div>
                                <p className="mt20">모니에서 내 소중한 정보를 확인하고, 내가 원하는 정보만 선택하여 내가 원하는 사람에게  공유할 수 있습니다.<br />의도치 않게 잘못 전달되어 내 정보가 유출되지 않도록 비밀번호 및 정보 열람 시간제한 등의 방법으로 내 정보 안전하게 보호합니다.<br />친구, 연인, 가족, 인사 및 보험담당자에게 내 정보를 공유해보세요!</p>
                                <div className="ifg mt45">

                                </div>
                                <p className="mt15">자랑하고 싶은 정보가 있다면? 자금 바로 모니!</p>
                                <ul className="stores mt30">
                                    <li className="app_store" data-href="https://apps.apple.com/kr/app/apple-store/id1447928072, _blank"></li>
                                    <li className="google_play" data-href="https://play.google.com/store/apps/details?id=com.fintech.smart.lenket, _blank"></li>
                                </ul>
                            </div>
                        </div>
                    </section>


                    <section className="ngns">
                        <div className="wrp w192">
                            <ul className="f_slider nscr xMandatory">
                                <li className="sli_01"></li>
                                <li className="sli_05"></li>
                                <li className="sli_04"></li>
                                <li className="sli_02"></li>
                                <li className="sli_03"></li>
                            </ul>
                            <i className="arrow prev hide"></i>
                            <i className="arrow next"></i>
                            <ul className="slider_dots">
                                <li className="on"></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                            </ul>
                        </div>
                    </section>

                </main>
            </div>

        </>
    );

};

export default Service;